import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Preloader from "../components/Preloader/Preloader";
import SEO from "../components/SEO";
import { NotFound } from "../pages/NotFound";
const ThemeMain = lazy(() => import("../themes/theme-main"));
const ThemeMainRestoran = lazy(() => import("../themes/theme-main-restaurant"));
const ThemeMainAutomation = lazy(() =>
  import("../themes/theme-main-automation")
);
const ThemeMainHealth = lazy(() => import("../themes/theme-main-health"));
const ThemeMainCommunal = lazy(() => import("../themes/theme-main-communal"));
const ThemeMainFlutter = lazy(() => import("../themes/theme-main-flutter"));
const ThemeMainMobile = lazy(() => import("../themes/theme-main-mobile"));
const Portfolio = lazy(() => import("../pages/Portfolio"));
const CaseSection = lazy(() => import("../pages/CaseSection"));

const SuspenseComponent = (LazyComponent, lang = "en", page = "ru") => (
  <Suspense fallback={<Preloader />}>
    <SEO lang={lang} page={page} />
    <LazyComponent lang={lang} page={page} />
  </Suspense>
);

const language = navigator.language.substr(0, 2);

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to={`/main/ru`} />
            </Route>
            <Route exact path="/restaurant/">
              <Redirect to={`/restaurant/ru`} />
            </Route>
            <Route exact path="/health/">
              <Redirect to={`/health/ru`} />
            </Route>
            <Route exact path="/communal/">
              <Redirect to={`/communal/ru`} />
            </Route>
            <Route exact path="/automation/">
              <Redirect to={`/automation/ru`} />
            </Route>
            <Route exact path="/flutter/">
              <Redirect to={`/flutter/ru`} />
            </Route>
            <Route
              exact
              path="/main/ru"
              component={() => SuspenseComponent(ThemeMain, "ru", "main")}
            />
            <Route
              exact
              path="/main/en"
              component={() => SuspenseComponent(ThemeMain, "en", "main")}
            />
            <Route
              exact
              path="/ru"
              component={() =>
                SuspenseComponent(ThemeMainMobile, "ru", "general")
              }
            />
            <Route
              exact
              path="/en"
              component={() =>
                SuspenseComponent(ThemeMainMobile, "en", "general")
              }
            />
            <Route
              exact
              path="/restaurant/ru"
              component={() =>
                SuspenseComponent(ThemeMainRestoran, "ru", "restaurant")
              }
            />
            <Route
              exact
              path="/automation/ru"
              component={() =>
                SuspenseComponent(ThemeMainAutomation, "ru", "automation")
              }
            />
            <Route
              exact
              path="/automation/en"
              component={() =>
                SuspenseComponent(ThemeMainAutomation, "en", "automation")
              }
            />
            <Route
              exact
              path="/restaurant/en"
              component={() =>
                SuspenseComponent(ThemeMainRestoran, "en", "restaurant")
              }
            />
            <Route
              exact
              path="/health/ru"
              component={() =>
                SuspenseComponent(ThemeMainHealth, "ru", "health")
              }
            />
            <Route
              exact
              path="/flutter/ru"
              component={() =>
                SuspenseComponent(ThemeMainFlutter, "ru", "flutter")
              }
            />
            <Route
              exact
              path="/flutter/en"
              component={() =>
                SuspenseComponent(ThemeMainFlutter, "en", "flutter")
              }
            />
            <Route
              exact
              path="/health/en"
              component={() =>
                SuspenseComponent(ThemeMainHealth, "en", "health")
              }
            />
            <Route
              exact
              path="/communal/ru"
              component={() =>
                SuspenseComponent(ThemeMainCommunal, "ru", "communal")
              }
            />
            <Route
              exact
              path="/communal/en"
              component={() =>
                SuspenseComponent(ThemeMainCommunal, "en", "communal")
              }
            />
            <Route
              exact
              path="/portfolio/ru"
              component={() => SuspenseComponent(Portfolio, "ru", "portfolio")}
            />
            <Route
              exact
              path="/portfolio/case/:id/ru"
              component={() => SuspenseComponent(CaseSection, "ru", "case")}
            />
            <Route
              exact
              path="/portfolio/case/:id/en"
              component={() => SuspenseComponent(CaseSection, "en", "case")}
            />
            <Route
              exact
              path="/portfolio/en"
              component={() => SuspenseComponent(Portfolio, "en", "portfolio")}
            />

            <Route
              path="/notfound"
              component={() => SuspenseComponent(NotFound, "ru")}
            />
            <Route
              path="*"
              status={404}
              component={() => <Redirect to={`/notfound`} />}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;
