import React, { Component } from 'react';
import logo from './logo.svg'

class Preloader extends Component {
    render() {

        const classes = this.props.hide ? 'fade-animation-leave fade-animation-leave-active' :
            'fade-animation-enter fade-animation-enter-active'

        return (
            <div className={`preloader ${classes}`}>
                <img className="preloader__image_animate" src="/img/ITWEBFOX WHITE_TXT2FIX_NEW.svg" alt="" />
            </div>
        )
    }
}

export default Preloader