import React, { Component } from "react";

export const NotFound = () => {
  return (
    <section className="not-found-page-wrapper">
      <div className="not-found-content-wrapper">
        <div className="not-found-status">
          <span className="not-found-status-404">404</span>
        </div>
        <div className="not-found-description">
          <h2>К сожалению, страница не найдена.</h2>
        </div>
      </div>
    </section>
  );
};
